import { apiRequest, cacheRequest } from "./base";

export const getSkuList = (data) => {
  const res = apiRequest.post("/v2/mall/sku", data);
  return res;
};

export const getSkuDetail = async (id) => {
  const token = localStorage.getItem("token");
  const url = `/v2/mall/sku/${id}`;
  const res = await cacheRequest.get(url);
  return res;
};

export const getCategory = (params) => {
  const res = cacheRequest.get("/v2/mall/sku/category/keyword", {
    params,
  });
  return res;
};

export const getFilter = (params) => {
  const res = cacheRequest.get("/v2/mall/sku/category/filter", {
    params,
  });
  return res;
};
